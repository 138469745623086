import elementUILocale from 'element-ui/lib/locale'

export const state = () => ({})

export const mutations = {}

export const actions = {
  setLanguage(_, { i18n, locale }) {
    const fallbackLocale = 'en-GB'
    const _locale = locale || fallbackLocale
    let foundLocale
    this.$i18n.availableLocales.forEach((availableLocale) => {
      if (availableLocale === _locale) {
        foundLocale = availableLocale
      }
    })
    if (foundLocale) {
      // console.log('Found in POEditor')
      this.$i18n.locale = foundLocale
      i18n.locale = foundLocale
    } else {
      console.log('Not found in POEditor - Fallback to en-GB')
      this.$i18n.locale = fallbackLocale
      i18n.locale = fallbackLocale
    }
    // Element UI locale set up
    elementUILocale.i18n((key, value) => this.$i18n.t(key, value))
  },
}
