export const state = () => ({
  windowWidth: null,
  windowHeight: null,
})

export const mutations = {
  setWindowWidth(state, value) {
    state.windowWidth = value
  },
  setWindowHeight(state, value) {
    state.windowHeight = value
  },
}

export const actions = {
}
