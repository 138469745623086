import styled from 'vue-styled-components'
import colors from '~/assets/js/theme'

const componentProps = {
  fill: String,
  size: {
    type: String,
    default: '24px',
  },
  width: String,
  height: String,
  important: Boolean
}

const StyledSvg = styled('span', componentProps)`
  &.ico {
    fill: ${(props) =>
    props.fill
      ? colors.knownColor(props.fill)
        ? `${colors.knownColor(props.fill)}${props.important ? ' !important' : ''}`
        : ''
      : ''};

    &,
    svg {
      width: ${(props) => (props.size ? props.size : props.width)};
      height: ${(props) => (props.size ? props.size : props.height)};
    }

    use,
    use + *,
    * {
      fill: ${(props) =>
    props.fill
      ? colors.knownColor(props.fill)
        ? `${colors.knownColor(props.fill)}${props.important ? ' !important' : ''}`
        : ''
      : ''};
    }
  }
`

export default StyledSvg
