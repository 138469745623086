
export default {
  props: {
    fullWidth: Boolean,
    uppercase: Boolean,
    action: String,
    disabled: Boolean,
    icon: {
      type: String,
      default: null,
    },
    loading: Boolean,
    small: Boolean,
    layout: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'tertiary'].includes(value)
      },
    },
  },
}
