export const state = () => ({
  country: null,
  language: null,
  tbsId: null
})

export const mutations = {
  setCountry(state, value) {
    state.country = value
  },
  setLanguage(state, value) {
    state.language = value
  },
  setTbsId(state, value) {
    state.tbsId = value
  }
}

export const actions = {
  setInitialData({ commit }, { country, language, tbsId, reportId, mode }) {
    commit('setCountry', country)
    commit('setLanguage', language)
    commit('setTbsId', tbsId)
  },
}
