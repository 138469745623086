import styled from 'vue-styled-components'

const componentProps = {
  size: String,
  hover: String,
  width: {
    type: String,
    default: 'auto',
  },
  height: {
    type: String,
    default: 'auto',
  },
}

const StyledSvgCustom = styled('span', componentProps)`
  &.ico {
    &,
    svg {
      width: ${(props) => (props.size ? props.size : props.width)};
      height: ${(props) => (props.size ? props.size : props.height)};
      fill: #fff;
    }
  }
`

export default StyledSvgCustom
