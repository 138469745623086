export const state = () => ({
  animations: {},
})

export const mutations = {
  setAnimations(state, value) {
    state.animations = value
  },
}

export const actions = {
  async getAnimations({ state, dispatch, commit }, queryProps) {
    const apiResult = await this.$apiAaltra.getAnimations(
      queryProps?.tbsId,
      queryProps?.country,
      queryProps?.language,
    )

    if (apiResult) {
      await commit(
        'setAnimations', apiResult
      )
    }
  },

}
