export const state = () => ({
  requestsCancelTokens: [],
})

export const mutations = {
  clearRequestsCancelToken(state) {
    state.requestsCancelTokens = []
  },
  setRequestsCancelToken(state, cancelSource) {
    state.requestsCancelTokens.push(cancelSource)
  },
}

export const actions = {
  cancelPendingRequests({ state, commit }) {
    // Cancel all request where a token exists
    state.requestsCancelTokens.forEach((request) => {
      if (request?.cancel) {
        request.cancel(
          '🥊 Response cancelled due to route change or by manual request'
        )
      }
    })
    // Reset the cancelTokens store
    commit('clearRequestsCancelToken')
  },
}
