export const state = () => ({
    isPopoverOpen: false,
})

export const mutations = {
    setPopopverOpenState(state, value) {
        state.isPopoverOpen = value
    },
}

export const actions = {
}
